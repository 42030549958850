import { Translations } from './utils/translations';
import {
  DAILY_TIMETABLE_WIDGET_CONTROLLER_ID,
  BOOK_BUTTON_WIDGET_CONTROLLER_ID,
  STAFF_LIST_WIDGET_CONTROLLER_ID,
  PageId,
  experiments,
  BOOKINGS_DEF_ID,
  PRICING_PLANS_DEF_ID,
} from './constants';
import {
  addBookingsPagesAsPanel,
  createBookCheckoutState,
  createBookingCalendarState,
  createBookingFormState,
  createServicePageState,
  handleOpenBookingsPagesPanel,
} from './utils/pages-panel-actions';
import {
  handlePagesPanelMigration,
  shouldProposeMigration,
} from './utils/migrate-actions';
import { getCurrentVersion } from './utils/ci-actions';
import {
  migrator,
  proposeListMigration,
  proposeServicePageMigration,
} from './utils/migration-modals';
import {
  getStateBoxByBookingsAppBuilderWidget,
  getAllBookingsPages,
  getBookingsData,
  getPageData,
  isBookingsCheckoutInstalled,
  onRemoveApp,
  removePage,
  getBookingsAppBuilderWidgetByChildComponentRef,
  isServicePageInstalled,
  isBookingCalendarInstalled,
} from './utils/editor-sdk-actions';
import {
  BookingsAppBuilderStaticsUrls,
  DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID,
  STAFF_LIST_WIDGET_DEV_CENTER_ID,
} from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';
import { DailyTimetableEditorComponentModel } from './editor-components/daily-timetable';
import { BookButtonEditorComponentModel } from './editor-components/book-button';
import { StaffListEditorComponentModel } from './editor-components/staff-list';

import { isExperimentEnabled } from './utils/experiments';
import {
  generateActions,
  manageBookingsEvent,
  manageStateEvent,
  openBookButtonSettingsEvent,
  openDailyTimeTableSettingsEvent,
  openStaffListSettingsEvent,
  manageStaffEvent,
  openDailyTimeTableSettingsTextTabEvent,
  openStaffListTextSettingsEvent,
  openBookingsAddPanel,
  openBookingsPagesPanel,
  createAServiceEvent,
} from './utils/editor-actions';
import { EditorSdkAdapter } from '@wix/bookings-adapter-editor-sdk/dist/src';
import {
  withMembersArea,
  maybeInstallMembersArea,
  MA_APP_IDS,
} from '@wix/members-area-integration-kit';
import createAppDescriptor from './manifest/app-descriptor/app-descriptor';
import { handleListMigration } from './utils/list-migration/list-migration';
import { handleServicePageMigration } from './utils/service-page-migration/service-page-migration';
import {
  openBookingsDashboardAddNewService,
  openManageBookings,
} from './utils/backoffice-actions';
import { handleOpenBookingsAddPanel } from './utils/app-descriptor-actions';

const checkIfUserIsPremium = (bookingsData) => {
  return bookingsData?.vendorProductId ?? false;
};
export const createEditorScript = () => {
  let instance,
    appToken,
    sdk,
    locale,
    isAdi,
    isProposeMigrationNeeded,
    allSitePages,
    isBookingCalendarPageInstalled;
  const bookingsManageStaffDashboardPanel = 'bookings/staff';
  const deleteBookingsEvent = 'deleteBookings';
  const deletePageEvent = 'deletePage';
  const pageChangedEvent = 'focusedPageChanged';
  const widgetGfppClicked = 'widgetGfppClicked';
  const componentGfppClicked = 'componentGfppClicked';
  const componentStyleChanged = 'componentStyleChanged';
  const gfppOriginDoubleClick = 'double_click';
  const appActionClicked = 'appActionClicked';

  const editorTranslation = new Translations();
  let bookingsEditorComponentModels;
  let actions;
  let appDescriptor;
  let isProGalleryEnabled;
  let isCalendarPageMigrationModalEnabled;

  const editorScript = {
    editorReady: async (_editorSDK, _appToken, options) => {
      if (
        await isExperimentEnabled(
          experiments.CHECKOUT_PAGE_PANEL_WITH_CALENDAR_PAGE,
        )
      ) {
        isBookingCalendarPageInstalled = await isBookingCalendarInstalled(
          _editorSDK,
        );
      }
      const editorSdkAdapter = new EditorSdkAdapter(_editorSDK, _appToken);
      locale = await editorSdkAdapter.getEditorLanguage();
      const currentVersion = await getCurrentVersion(editorSdkAdapter);
      const bookingsData = await getBookingsData(_editorSDK, appToken);
      instance = bookingsData.instance;
      await editorTranslation.init(locale, currentVersion);
      actions = generateActions(editorTranslation);
      isProGalleryEnabled = await isExperimentEnabled(
        experiments.PRO_GALLERY_ENABLED,
      );
      isCalendarPageMigrationModalEnabled = await isExperimentEnabled(
        experiments.CALENDAR_PAGE_MIGRATION_MODAL,
      );
      const isCreateDescriptorEnabled = await isExperimentEnabled(
        experiments.BOOKINGS_MANAGE_MY_APP,
      );
      if (isCreateDescriptorEnabled) {
        const isPremium = checkIfUserIsPremium(bookingsData);
        appDescriptor = createAppDescriptor(
          actions,
          isPremium,
          editorTranslation,
        );
      }
      const [
        isMultiLocationEnabledInOs,
        isMultiLocationEnabledInUou,
        isConcurrentEditingEnabled,
      ] = await Promise.all([
        isExperimentEnabled(experiments.OS_MULTI_LOCATION_ENABLED),
        isExperimentEnabled(experiments.UOU_MULTI_LOCATION_V1),
        isExperimentEnabled(experiments.CONCURRENT_EDITING_ENABLED),
      ]);
      bookingsEditorComponentModels = {
        [DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID]:
          new DailyTimetableEditorComponentModel(
            editorSdkAdapter,
            editorTranslation,
            actions.manageBookingsAction,
            actions.manageStateAction,
            actions.openDailyTimeTableSettings,
            actions.openDailyTimeTableSettingsTextTab,
            isMultiLocationEnabledInOs && isMultiLocationEnabledInUou,
          ),
        [BOOK_BUTTON_WIDGET_CONTROLLER_ID]: new BookButtonEditorComponentModel(
          editorSdkAdapter,
          editorTranslation,
          actions.manageBookingsAction,
          actions.bookButtonSettings,
        ),
        [STAFF_LIST_WIDGET_DEV_CENTER_ID]: new StaffListEditorComponentModel(
          editorSdkAdapter,
          editorTranslation,
          {
            manageStaffAction: actions.manageStaffAction,
            staffListSettingsAction: actions.openStaffListSettings,
            openStaffListTextSettingsAction: actions.openStaffListTextSettings,
          },
        ),
      };
      await editorSdkAdapter.registerToCustomEvents([componentStyleChanged]);
      return new Promise(async (resolve) => {
        appToken = _appToken;
        sdk = _editorSDK;
        isAdi = options.origin && options.origin.type === 'ADI';
        if (await isBookingsCheckoutInstalled(sdk)) {
          await addBookingsPagesAsPanel(sdk, appToken);
        }
        // if (!isAdi && await isNoBookingsPagesInstalled(sdk)) {
        //   await handleBrokenNoPagesMigration(sdk, appToken);
        //   return Promise.resolve();
        // }
        if (options && options.firstInstall) {
          const setupBookingsBoilerplate = async () => {
            await addBookingsPagesAsPanel(sdk, appToken);
            const isIntroFunnelInstallation =
              options.origin?.info?.type === 'INTRO_FUNNEL';
            if (!isAdi && !isIntroFunnelInstallation) {
              await maybeInstallMembersArea({ biData: options.biData });
            }
          };
          if (isConcurrentEditingEnabled) {
            try {
              await sdk.document.transactions.runAndWaitForApproval(
                appToken,
                async () => {
                  await setupBookingsBoilerplate();
                },
              );
            } catch (error) {
              console.error('Concurrent editing problem: ', error);
            }
          } else {
            await setupBookingsBoilerplate();
          }
        } else {
          await sdk.document.application.registerToCustomEvents(appToken, {
            eventTypes: [pageChangedEvent],
          });
          isProposeMigrationNeeded = shouldProposeMigration(
            sdk,
            isAdi,
            appToken,
            instance,
          );
        }
        allSitePages = await sdk.pages.data.getAll();
        await createBookCheckoutState(sdk, appToken, allSitePages);
        await createServicePageState(sdk, appToken, allSitePages);
        await createBookingCalendarState(sdk, appToken, allSitePages);
        await createBookingFormState(sdk, appToken, allSitePages);
        resolve(undefined);
      });
    },
    getAppManifest: () => {
      return {
        ...(appDescriptor ? { appDescriptor } : {}),
        controllersStageData: {
          [DAILY_TIMETABLE_WIDGET_CONTROLLER_ID]: {
            default:
              bookingsEditorComponentModels[
                DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID
              ].connection,
          },
          [BOOK_BUTTON_WIDGET_CONTROLLER_ID]: {
            default:
              bookingsEditorComponentModels[BOOK_BUTTON_WIDGET_CONTROLLER_ID]
                .connection,
          },
          [STAFF_LIST_WIDGET_CONTROLLER_ID]: {
            default:
              bookingsEditorComponentModels[STAFF_LIST_WIDGET_DEV_CENTER_ID]
                .connection,
          },
        },
        pages: {
          pageActions: {
            default: [
              'Pages_Actions_Page_Rename',
              {
                title: editorTranslation.t('bookings-pages.page.delete'),
                event: deletePageEvent,
                icon: 'deleteAction',
                type: 'page_remove',
              },
            ],
            bookCheckoutPage: [],
            servicePage: [],
            bookingCalendar: [],
            bookingForm: [],
          },
          pageSettings: {
            default: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.seo'),
                type: 'seo',
              },
            ],
            bookCheckoutPage: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/book-checkout-page-info?locale=${locale}&instance=${instance}&isBookingCalendarInstalled=${isBookingCalendarPageInstalled}`,
                helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
            servicePage: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/service-page-info?locale=${locale}&instance=${instance}`,
                helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
            bookingCalendar: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/booking-calendar-page-info?locale=${locale}&instance=${instance}`,
                helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
            bookingForm: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/booking-form-page-info?locale=${locale}&instance=${instance}`,
                helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
          },
          applicationSettings: {
            default: {
              displayName: editorTranslation.t('bookings-pages.title'),
              helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
            },
          },
          applicationActions: {
            default: {
              defaultValues: [
                {
                  title: editorTranslation.t('bookings-pages.actions.manage'),
                  event: manageBookingsEvent,
                  icon: 'settingsAction',
                },
                {
                  title: editorTranslation.t('bookings-pages.actions.delete'),
                  event: deleteBookingsEvent,
                  icon: 'deleteRadio',
                },
              ],
            },
          },
          pageDescriptors: {
            default: {
              icon: 'bookingPageType',
              orderIndex: 5,
            },
            servicePage: {
              icon: 'bookingPageType',
              orderIndex: 4,
            },
            bookingCalendar: {
              icon: 'bookingPageType',
              orderIndex: 3,
            },
            bookingForm: {
              icon: 'bookingPageType',
              orderIndex: 2,
            },
            bookCheckoutPage: {
              icon: 'bookingPageType',
              orderIndex: 1,
            },
          },
        },
      };
    },
    onEvent: async ({ eventType, eventPayload }, editorSDK) => {
      const editorSdkAdapter = new EditorSdkAdapter(editorSDK, appToken);

      async function openManageStaff() {
        await editorSdkAdapter.openStaffList('editor');
        editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE');
      }

      function focusOnStateBox(widgetComponentRef) {
        getStateBoxByBookingsAppBuilderWidget(
          editorSDK,
          appToken,
          widgetComponentRef,
        ).then((stateBoxComponentRef) => {
          editorSdkAdapter.selectComponent(stateBoxComponentRef);
        });
      }

      function getWidgetComponentRef(componentRef) {
        getBookingsAppBuilderWidgetByChildComponentRef(
          editorSDK,
          appToken,
          componentRef,
        ).then((stateBoxComponentRef) => {
          editorSdkAdapter.selectComponent(stateBoxComponentRef);
        });
      }
      async function openDailyTimeTableSettingsPanel(
        componentRef,
        tab?: 'text',
      ) {
        const innerPath = tab ? `/${tab}` : ``;

        const url = `https://bookings.wixapps.net/bookings-app-builder-statics/daily-timetable/settings${innerPath}`;
        const title = editorTranslation.t(
          'bookings.daily-timetable.settings.label.Header',
        );
        const helpId = 'b78664ce-6970-4eb8-9ee6-6ce1d79d9b61';
        const options = {
          width: 406,
        };
        await editorSdkAdapter.openSettingsPanel(
          title,
          url,
          componentRef,
          helpId,
          instance,
          options,
        );
      }

      async function openStaffListSettingsPanel(componentRef, tab = '') {
        const url = `https://bookings.wixapps.net/bookings-app-builder-statics/staff-widget/settings/${tab}`;
        const title = editorTranslation.t(
          'bookings.staff-list.settings.label.Header',
        );
        const helpId = '8a5134e7-7689-4772-acc4-fb2a9830b54e';
        const options = {
          width: 406,
        };
        await editorSdkAdapter.openSettingsPanel(
          title,
          url,
          componentRef,
          helpId,
          instance,
          options,
        );
      }

      async function openBookButtonSettingsPanel(componentRef) {
        const url = `${BookingsAppBuilderStaticsUrls.BOOK_BUTTON_SETTINGS}`;
        const title = editorTranslation.t(
          'bookings.book-button.settings.label.Header',
        );
        const helpId = '0b25d4f6-6381-4d40-ac59-97a7d14cc7c2';
        await editorSdkAdapter.openSettingsPanel(
          title,
          url,
          componentRef,
          helpId,
          instance,
        );
      }

      async function handleComponentStyleChange() {
        const styledComponentRef = eventPayload.compRef;
        const linkedBookingsWidget = await getParentWidget(styledComponentRef);
        if (linkedBookingsWidget) {
          const { widgetComponentRef, editorComponentModel } =
            linkedBookingsWidget;
          await editorComponentModel.handleStylesChange(
            widgetComponentRef,
            styledComponentRef,
          );
        }
      }

      async function getParentWidget(componentRef) {
        const ancestors = await editorSDK.components.getAncestors(appToken, {
          componentRef,
        });
        for (const ancestor of ancestors) {
          const ancestorId = await editorSdkAdapter.getWidgetId(ancestor);
          if (bookingsEditorComponentModels[ancestorId]) {
            return {
              widgetComponentRef: ancestor,
              editorComponentModel: bookingsEditorComponentModels[ancestorId],
            };
          }
        }
        return null;
      }

      async function getParentWidgetCompRef(childComponentRef) {
        const ancestors = await editorSDK.components.getAncestors(appToken, {
          componentRef: childComponentRef,
        });
        for (const ancestor of ancestors) {
          const data = await editorSDK.components.data.get(appToken, {
            componentRef: ancestor,
          });
          if (data?.type === 'AppController') {
            return ancestor;
          }
        }
        return null;
      }

      async function handleDoubleClick() {
        let parentWidget;
        try {
          parentWidget = await getParentWidget(eventPayload.componentRef);
        } catch (e) {
          // todo try for book-button
        }
        if (
          parentWidget?.editorComponentModel.baseComponentModel
            .doubleClickAction
        ) {
          eventPayload.id =
            parentWidget.editorComponentModel.baseComponentModel.doubleClickAction.actionId;
        }
      }

      switch (eventType) {
        case appActionClicked:
          if (eventPayload.actionId === manageBookingsEvent) {
            void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          } else if (eventPayload.actionId === openBookingsAddPanel) {
            void handleOpenBookingsAddPanel(editorSDK, appToken);
          } else if (eventPayload.actionId === openBookingsPagesPanel) {
            void handleOpenBookingsPagesPanel(editorSDK, appToken);
          } else if (eventPayload.actionId === createAServiceEvent) {
            void openBookingsDashboardAddNewService(
              editorSdkAdapter,
              editorSDK,
              appToken,
            );
          }
          break;
        case componentStyleChanged:
          void handleComponentStyleChange();
          break;
        case widgetGfppClicked:
          if (eventPayload.gfppOrigin === gfppOriginDoubleClick) {
            await handleDoubleClick();
          }
          if (eventPayload.id === manageBookingsEvent) {
            void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(eventPayload.componentRef);
          }
          if (eventPayload.id === openBookButtonSettingsEvent) {
            void openBookButtonSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === openDailyTimeTableSettingsEvent) {
            void openDailyTimeTableSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === openStaffListSettingsEvent) {
            void openStaffListSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === manageStaffEvent) {
            void openManageStaff();
          }
          break;
        case componentGfppClicked:
          const parentWidget = await getParentWidgetCompRef(
            eventPayload.componentRef,
          );
          if (eventPayload.id === manageBookingsEvent) {
            void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          }
          if (eventPayload.id === openStaffListSettingsEvent) {
            void openStaffListSettingsPanel(parentWidget);
          }
          if (eventPayload.id === manageStaffEvent) {
            void openManageStaff();
          }
          if (eventPayload.id === openDailyTimeTableSettingsTextTabEvent) {
            void openDailyTimeTableSettingsPanel(parentWidget, 'text');
          }
          if (eventPayload.id === openDailyTimeTableSettingsEvent) {
            void openDailyTimeTableSettingsPanel(parentWidget);
          }
          if (eventPayload.id === openStaffListTextSettingsEvent) {
            void openStaffListSettingsPanel(parentWidget, 'text');
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(parentWidget);
          }
          break;
        case manageBookingsEvent:
          void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          break;
        case manageStateEvent:
          focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          break;
        case deleteBookingsEvent:
          const bookingsPages = await getAllBookingsPages(editorSDK, appToken);
          const essentialPage = bookingsPages.find(
            (page) => page.tpaPageId === PageId.BOOKINGS_LIST,
          );
          await removePage(editorSDK, appToken, essentialPage.id);
          break;
        case deletePageEvent:
          await removePage(editorSDK, appToken, eventPayload.pageRef.id);
          break;
        case pageChangedEvent:
          const pageData = await getPageData(
            sdk,
            appToken,
            eventPayload.pageRef,
          );
          if (
            pageData.tpaPageId === PageId.SCHEDULER &&
            isProposeMigrationNeeded &&
            !(await isBookingsCheckoutInstalled(editorSDK))
          ) {
            await proposeListMigration(
              sdk,
              appToken,
              locale,
              instance,
              editorTranslation,
              allSitePages,
            );
          } else {
            if (!isCalendarPageMigrationModalEnabled) {
              if (
                pageData.tpaPageId === PageId.BOOKINGS_CHECKOUT &&
                isProGalleryEnabled &&
                !(await isServicePageInstalled(sdk)) &&
                !isAdi
              ) {
                await proposeServicePageMigration(
                  sdk,
                  appToken,
                  locale,
                  instance,
                  editorTranslation,
                );
              }
            } else {
              if (
                pageData.tpaPageId === PageId.BOOKINGS_CHECKOUT &&
                (!(await isServicePageInstalled(sdk)) ||
                  !(await isBookingCalendarInstalled(sdk))) &&
                !isAdi
              ) {
                await migrator({
                  sdk,
                  appToken,
                  locale,
                  instance,
                  editorTranslation,
                  allSitePages,
                });
              }
            }
          }
          break;
        default:
      }
    },
    handleAction: async (args) => {
      const type = args.type,
        payload = args.payload;
      const withServicePage = payload?.withServicePage;
      const isJustToInstallPricingPlans = payload?.withPricingPlans;
      try {
        switch (type) {
          case 'migrate':
            if (isJustToInstallPricingPlans) {
              return sdk.document.tpa.add.application(appToken, {
                appDefinitionId: PRICING_PLANS_DEF_ID,
              });
            }
            if (await isBookingsCheckoutInstalled(sdk)) {
              const shouldInstallServicePage =
                withServicePage && !(await isServicePageInstalled(sdk));
              shouldInstallServicePage &&
                (await handleServicePageMigration(
                  sdk,
                  appToken,
                  instance,
                  editorTranslation,
                ));
              await handlePagesPanelMigration(sdk, appToken, allSitePages);
              return Promise.resolve();
            } else {
              const shouldEnableProgressBar = !isAdi;
              return handleListMigration(
                sdk,
                appToken,
                editorTranslation,
                shouldEnableProgressBar,
              );
            }
          case 'removeApp':
            return onRemoveApp(sdk, appToken);
          default:
            return Promise.resolve();
        }
      } catch (e) {
        Promise.reject(e);
        throw e;
      }
    },
    getControllerPresets: async () => {
      return [];
    },
  };

  return withMembersArea(editorScript, {
    installAutomatically: false,
    membersAreaApps: [MA_APP_IDS.MY_BOOKINGS, MA_APP_IDS.MY_WALLET],
  });
};
